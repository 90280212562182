import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, {
  type FunctionComponent,
  type JSX,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  buttonClasses
} from '@mui/material';

import type { PureFunction } from '@bonsai-components/utility-types';
import { SignInFooter } from '../../components/authentication/signin/signin-footer.component';
import { SignInHeader } from '../../components/authentication/signin/signin-header.component';
import { UnauthenticatedLayout } from '../../components/layout/unauthenticated-layout.component';
import { TENANT_BRANDING_ASPECT_RATIO } from '../../constants/general';
import { KNOWN_SIGN_IN_ERRORS } from '../../constants/messages';
import {
  type AuthProviderNames,
  ConfigurationContext
} from '../../contexts/config.context';
import { BitBucketIcon, GitHubIcon, GitLabIcon } from '../../icons/icons';

export type SignInOptions = {
  kc_idp_hint?: 'github' | 'bitbucket' | 'gitlab';
};

export type MultiTenantAuthProvider = {
  id: AuthProviderNames;
  name: string;
  idpHint?: SignInOptions['kc_idp_hint'];
  icon?: JSX.Element;
};

export const authProviderConfig: MultiTenantAuthProvider[] = [
  {
    id: 'github',
    name: 'GitHub',
    icon: <GitHubIcon />
  },
  {
    id: 'bitbucket',
    name: 'Bitbucket Cloud',
    idpHint: 'bitbucket',
    icon: <BitBucketIcon />
  },
  {
    id: 'gitlab',
    name: 'GitLab',
    idpHint: 'gitlab',
    icon: <GitLabIcon />
  },
  {
    id: 'sso',
    name: 'SSO'
  }
];

export const mapAuthProvider = (
  provider: AuthProviderNames
): MultiTenantAuthProvider => authProviderConfig.find((p) => p.id === provider);

export const getAuthProviderOptions: PureFunction<
  AuthProviderNames,
  Record<string, string>
> = (provider) => {
  const providerConfig = mapAuthProvider(provider);
  const options: Record<string, string> = {};
  if (providerConfig?.idpHint) {
    options.kc_idp_hint = providerConfig.idpHint;
  }
  return options;
};

const SignIn: FunctionComponent = () => {
  const router = useRouter();
  const { config, getAuthProviders } = useContext(ConfigurationContext);

  const [requestedProvider, setRequestedProvider] =
    useState<AuthProviderNames>(null);

  const isMultiTenant = config?.name === 'app';
  const errorCode = router.query.error as string;

  const handleSignIn = useCallback(
    (providerId?: AuthProviderNames) => {
      // update button states
      setRequestedProvider(providerId);

      // get any provider options
      const providerOptions = providerId
        ? getAuthProviderOptions(providerId)
        : null;

      // sanitize the callback url
      const callbackUrlFromQuery = router.query.callbackUrl as string;
      let callbackUrl;
      try {
        // remove the `error` from the query params
        const parsedCallbackUrl = new URL(callbackUrlFromQuery);
        parsedCallbackUrl.searchParams.delete('error');
        parsedCallbackUrl.searchParams.delete('startAuth');
        callbackUrl = parsedCallbackUrl.toString();
      } catch {
        callbackUrl = callbackUrlFromQuery || '/';
      }

      return signIn(
        'keycloak',
        {
          // work around for infinite redirect issue
          // @see https://github.com/nextauthjs/next-auth/issues/4234
          callbackUrl,
          redirect: false
        },
        providerOptions ?? null
      ).then(() => setRequestedProvider(null));
    },
    [router.query.callbackUrl]
  );

  useEffect(() => {
    if (router?.query?.startAuth) {
      handleSignIn();
    }
  }, [handleSignIn, router.query?.startAuth]);

  return (
    <UnauthenticatedLayout>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          gap: { xs: 1, sm: 10 },
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box>
          <SignInHeader />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <SignInFooter isMultiTenant={isMultiTenant} />
          </Box>
        </Box>
        <Box
          sx={{
            width: 350,
            height: { xs: 200, sm: 400 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Stack
            direction="column"
            sx={{
              gap: 1,
              alignItems: 'center',
              minWidth: 280
            }}
          >
            {config?.brandingWhite && !errorCode ? (
              <Box
                sx={{
                  zIndex: 1
                }}
              >
                <Box
                  sx={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    minHeight: 80,
                    aspectRatio: TENANT_BRANDING_ASPECT_RATIO
                  }}
                >
                  <img
                    src={decodeURIComponent(config.brandingWhite)}
                    alt={config.name}
                    style={{
                      alignSelf: 'center',
                      maxWidth: 180,
                      maxHeight: 80,
                      minHeight: 30,
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Typography
                variant="h2"
                sx={{
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: 600,
                  marginBottom: 2,
                  fontSize: { xs: errorCode ? '1.75rem' : '2.125rem' }
                }}
              >
                {errorCode ? (
                  Object.values(KNOWN_SIGN_IN_ERRORS).includes(errorCode) ? (
                    errorCode
                  ) : (
                    <React.Fragment>
                      An error occurred, <br />
                      please sign in.
                    </React.Fragment>
                  )
                ) : (
                  'Welcome!'
                )}
              </Typography>
            )}
            <Stack gap={2.5} sx={{ width: '90%' }}>
              {getAuthProviders()
                .map(mapAuthProvider)
                .map((provider) => (
                  <Button
                    key={provider.name}
                    data-testid={`login-button-${provider.name}`}
                    startIcon={provider.icon}
                    color="primary"
                    variant="contained"
                    fullWidth={true}
                    sx={{
                      [`&.${buttonClasses.loading}`]: {
                        color: 'white',
                        backgroundColor: 'rgba(35, 63, 175, .2)',
                        borderColor: '#174AFF'
                      },
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'rgba(76, 79, 95, 1)',
                      backgroundColor: 'rgba(76, 79, 95, 1)',
                      [`&:hover`]: {
                        backgroundColor: 'rgba(35, 63, 175, .2)',
                        borderColor: '#174AFF',
                        boxShadow: (theme) => theme.shadows[2]
                      }
                    }}
                    loading={provider?.id === requestedProvider}
                    loadingIndicator={
                      <CircularProgress size={14} sx={{ color: 'white' }} />
                    }
                    loadingPosition="start"
                    onClick={() => handleSignIn(provider.id)}
                  >
                    Sign in with {provider.name}
                  </Button>
                ))}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </UnauthenticatedLayout>
  );
};

export default SignIn;
